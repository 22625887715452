import { graphql } from 'gatsby';
import { string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';

import { Content } from '../../common/content';
import './minimal.scss';

const bem = new BEMHelper({
  name: 'content-minimal-heading',
});

export const MinimalHeading = ({
  headline,
}) => (
  <Content {...bem()} useOwnSpacing>
    <h1 {...bem('headline')}>{headline}</h1>
  </Content>
);

MinimalHeading.propTypes = {
  headline: string.isRequired,
};

export const fragment = graphql`
  fragment MinimalHeadingFragment on CraftGQL_pageHeadings_minimalHeading_BlockType {
    headline
  }
`;
